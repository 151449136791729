import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Container } from "../components/elements/Container";
import Section from "../components/elements/Section";
import SEO from "../components/elements/SEO";
import { Text } from "../components/elements/Text";
import Layout from "../components/layout/Layout";

const query = graphql`
  query {
    strapiPrivacyPolicyPage {
      policy
      pageInfo {
        seoTitle
      }
    }
  }
`;

function PrivacyPolicyPage() {
  const { strapiPrivacyPolicyPage } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title={strapiPrivacyPolicyPage.pageInfo?.seoTitle} />
      <Section style={{ marginTop: 0 }}>
        <Container style={{ maxWidth: "580px" }}>
          <Text>
            <ReactMarkdown>{strapiPrivacyPolicyPage.policy}</ReactMarkdown>
          </Text>
        </Container>
      </Section>
    </Layout>
  );
}

export default PrivacyPolicyPage;
